.selectionOuter{
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100vw;
}

.selectionWrapper{
    display: flex;
    position: relative;
    height: 7.5vh;
    width: 100vw;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-direction: row;
}

.selectionCard{
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.664);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    width: 15vw;
    height: auto;
    z-index: 49;
    align-items: center;
    transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    border-top-left-radius: 1.3rem;  
    border-top-right-radius: 1.3rem;  
    font-family: 'Alexandria', sans-serif; 
    color: antiquewhite;
}

.selectionCard > div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 4vh;
    width: 15vw;
}

.selectionCard > div > p {
    margin: 0;
    padding: 0;
    font-size: 0.9vw;
}

@media (max-width:1920px){
.selectionCard > div > p{
    font-size: 1.1vw;
}
}

@media (max-width:1000px){
.selectionCard > div > p{
    font-size: 1.9vw;
}
}

.selectionCard > button{
    margin: 0;
    padding: 0;
    padding-top: 0.5vh;
    height: 7.5vh;
    width: 15vw;
    background-color: rgba(255, 0, 0, 0);
    border: none;
}

.cardIcon{
    font-size: 4.5rem;
    color: antiquewhite;
    transition: 0.3s ease-in-out;
}

.cardIcon:hover{
    color: rgb(78, 74, 74);
}

.selectionCard:hover{   
    transform: translateY(-7.5vh);
}

@media (max-width:414px),(max-width:900px){
.selectionOuter{
    height: auto;
    background-color: rgba(17, 219, 219, 0);
    position: relative;
    padding: 0;
    margin: 0;
    z-index: 98;
}

.selectionWrapper{
    border-top: 10px solid rgba(255, 255, 255, 0.158);
    // border-bottom: 10px solid rgba(255, 255, 255, 0.158);
    padding-top: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
}

.selectionCard{
    border-radius: 1.3rem; 
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 95vw;
    height: 6vh;
    min-height: 3rem;
    margin: 0.3rem;
    z-index: 99;
}

.selectionCard:hover{
    transform: translateY(0rem); 
}

.selectionCard > div{
    width: 30vw;
    height: auto;
    z-index: 999;
}

.selectionCard > div > p{
    margin-left: 2vw;
    font-size: 1rem;
    text-align: left;
    width: 30vw;
    height: auto;
    z-index: 999;
}

.selectionCard > button{
    background-color: rgb(87, 86, 88);
    display: flex;
    font-size: 3rem;
    height: 6vh;
    min-height: 3rem;
    color: rgba(250, 235, 215, 0);
    transition: 0.3s ease-in-out;
    border-top-left-radius: 1.3rem; 
    border-bottom-left-radius: 1.3rem; 
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.selectionCard > button > i{
    color: rgb(37, 36, 36);
    font-size: 2rem;
    margin: 0;
    padding: 0;
}
}

@media (max-width:900px){
    
.selectionCard{
    height: 8vh;
}

.selectionCard > button{
    height: 8vh;
}
}