.splashImage{
    width: 100vw;
    height: 90vh;
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1671033471/Gallery%20Website/BackPurp_vodbzw.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    position: absolute;
    filter: blur(9px);
    padding: 0;
    margin: 0;
    z-index: -1;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 3s;
}

.splashText{
    width: 100vw;
    height: 90vh;
    background-color: rgba(255, 0, 0, 0);
    color: aliceblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    padding: 0;
    margin: 0;
}

.splashText > span:nth-child(1){
    font-size: 5rem;
    font-family: 'Alexandria', sans-serif;
    font-weight: 100;
}

.splashText > span:nth-child(2){
    font-size: 2rem;
    font-weight: 100;
}

@media (max-width:414px){

.splashText > span:nth-child(1){
    font-size: 15vw;
}

.splashText > span:nth-child(2){
    font-size: 5.5vw;
}

.splashImage{
    height: 90vh;
}
}

@media (max-width:900px){

.splashText > span:nth-child(1){
    font-size: 10vw;
}

.splashText > span:nth-child(2){
    font-size: 4vw;
}

.splashImage{
    height: 90vh;
}
}


