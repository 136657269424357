@import "https://fonts.googleapis.com/css2?family=Alexandria:wght@200;300;400&family=Major+Mono+Display&family=Montserrat:wght@400;500&display=swap";
.headerBar {
  -webkit-backdrop-filter: saturate(180%);
  backdrop-filter: saturate(180%);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  width: 100vw;
  z-index: 9999;
  height: 4rem;
  background-color: #000c;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  position: fixed;
  overflow: hidden;
}

.navIcon {
  color: #fff;
  padding-left: 1rem;
  font-size: 2.9vw;
}

.headerLogo {
  width: 80px;
  height: 80px;
  color: #e9e1e1;
  padding-left: .75rem;
}

.headerTitle {
  color: #fff;
  padding-left: .75rem;
  font-family: Alexandria, sans-serif;
  font-size: 2rem;
}

.aboutControls {
  width: 85vw;
  justify-content: flex-end;
  padding: auto;
  transition: all 1s ease-in-out;
  display: flex;
  position: absolute;
}

.resizeButton {
  color: #fff;
  z-index: 99;
  background-color: #272525af;
  padding: .7rem;
  font-size: .7rem;
  transition: all .15s ease-in-out;
}

.resizeButton:hover {
  color: #626262;
}

@media (max-width: 414px), (max-width: 900px) {
  .headerTitle {
    font-size: 2vh;
  }

  .headerBar {
    height: 4vh;
  }
}

.splashImage {
  width: 100vw;
  height: 90vh;
  filter: blur(9px);
  z-index: -1;
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1671033471/Gallery%20Website/BackPurp_vodbzw.jpg");
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
  transition: all 3s cubic-bezier(.075, .82, .165, 1);
  display: flex;
  position: absolute;
}

.splashText {
  width: 100vw;
  height: 90vh;
  color: #f0f8ff;
  background-color: #f000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 2rem;
  display: flex;
}

.splashText > span:nth-child(1) {
  font-family: Alexandria, sans-serif;
  font-size: 5rem;
  font-weight: 100;
}

.splashText > span:nth-child(2) {
  font-size: 2rem;
  font-weight: 100;
}

@media (max-width: 414px) {
  .splashText > span:nth-child(1) {
    font-size: 15vw;
  }

  .splashText > span:nth-child(2) {
    font-size: 5.5vw;
  }

  .splashImage {
    height: 90vh;
  }
}

@media (max-width: 900px) {
  .splashText > span:nth-child(1) {
    font-size: 10vw;
  }

  .splashText > span:nth-child(2) {
    font-size: 4vw;
  }

  .splashImage {
    height: 90vh;
  }
}

.signatureContainer {
  width: 100vw;
  justify-content: center;
  margin-top: 2vh;
  padding-bottom: 2rem;
  display: flex;
}

.signatureWrapper {
  display: flex;
}

.signatureLeftSide {
  flex-direction: column;
  align-items: center;
  padding-right: .3rem;
  display: flex;
}

.signatureImage {
  width: 10rem;
  border-radius: 100rem;
  margin-bottom: .7rem;
}

.signatureText {
  color: #fff;
  height: 100%;
  border-left: .2rem solid #fff;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: .7rem;
  padding-right: 1vw;
  list-style: none;
  display: flex;
}

.signatureName {
  font-size: 2rem;
  font-style: bold;
}

.signatureTitle {
  margin-top: -.5rem;
  margin-bottom: 2rem;
  padding-top: 0;
  font-style: italic;
}

.signatureTopWord {
  padding-top: 2rem;
}

.signatureSignature {
  width: 5rem;
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.iconContainer {
  background-color: #0ff0;
  justify-content: space-evenly;
  display: flex;
}

.icon {
  color: #fff;
  background-color: #9f51cc46;
  border-radius: 1rem;
  margin-left: .3rem;
  margin-right: .3rem;
  padding: .4rem;
  font-size: 2rem;
  transition: all .3s ease-in-out;
}

.icon:hover {
  background-color: #9f51cc;
}

@media (max-width: 414px), (max-width: 900px) {
  .signatureName {
    font-size: 1.3rem;
  }

  .signatureContainer {
    transform: scale(.75);
  }
}

.bodySectionWrapper {
  min-height: 25vh;
  height: auto;
  width: 100vw;
  z-index: 515;
  background-color: #1b1b1b;
  border-bottom: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.bodySection {
  width: 100vw;
  z-index: 50;
  height: auto;
  border-bottom: 1px solid #f000;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 2rem 0 0;
  transition: all .75s ease-in-out;
  display: flex;
  position: relative;
}

.aboutMe, .fullaboutMe {
  width: 85vw;
  height: auto;
  text-overflow: ellipsis;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #302d2d;
  border-top: 4px solid;
  border-image: linear-gradient(90deg, #3b00ff 0%, #dc1be4 52%, #e6bdf4) 1 0 0;
  border-bottom-right-radius: .7rem;
  border-bottom-left-radius: 1rem;
  flex-direction: row;
  margin-bottom: 3vh;
  padding: 1rem;
  display: flex;
  overflow-y: scroll;
  box-shadow: 0 5px 15px #5f567256;
}

.aboutMe::-webkit-scrollbar, .fullaboutMe::-webkit-scrollbar {
  display: none;
}

.fullaboutMe > div > img {
  display: none;
}

.aboutTitleText, .aboutTitleText:visited {
  color: #fff;
  font-family: Alexandria, sans-serif;
  font-style: bold;
  width: auto;
  max-width: 85vw;
  height: auto;
  z-index: 55;
  align-items: center;
  margin-top: -1rem;
  margin-bottom: -.7rem;
  padding-top: 1rem;
  font-size: 2rem;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  transition: all .3s ease-in-out;
}

.aboutTitleText:hover, .aboutTitleText:visited:hover {
  color: #626262;
}

.aboutText {
  color: #fff;
  border-top: 1px solid #f0ecec;
  padding-top: 1vh;
  font-size: 2vh;
  transition: all .6s ease-in-out;
  display: none;
}

.aboutImg {
  width: 15vw;
  height: auto;
  aspect-ratio: auto;
  border: 2px solid #fff;
  margin-top: 2vh;
}

.aboutLeftCol {
  flex-direction: column;
  display: flex;
}

.bodyVideoWrapper {
  width: 100%;
  border-top: 1px solid #f0ecec;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.bodyVideo, .embedSlides {
  width: 85vw;
  height: auto;
  aspect-ratio: 16 / 9;
  border: none;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
}

.embedSlides {
  aspect-ratio: 16 / 10;
}

@media (max-width: 414px), (max-width: 900px) {
  .bodySection {
    height: auto;
    margin-top: 1rem;
    padding-top: 1rem;
  }

  div.bodySection:before {
    content: "";
    width: 70vw;
    border-top: 1px solid #fff;
    position: absolute;
    top: 0;
  }

  .aboutMe {
    max-height: 30vh;
    margin: 0;
  }

  .aboutTitleText, .aboutTitleText:visited {
    font-size: 2vh;
  }

  .aboutText {
    font-size: 1rem;
  }

  .aboutImg {
    width: 30vw;
  }

  .fa-link {
    font-size: 1.5rem;
    transform: scale(.5);
  }
}

.galleryTitle {
  width: 100vw;
  border-top: 1px solid #fff;
}

div.galleryTitle:before {
  content: "";
  width: 70vw;
  border-top: 1px solid #fff;
  position: absolute;
  top: 0;
}

.galleryTitle > p:nth-child(1) {
  color: #fff;
  padding-left: 5vh;
  font-size: 2rem;
}

.galleryTitle > p:nth-child(2) {
  color: #575050;
  margin-top: -2rem;
  padding-left: 5vh;
  font-size: 1.2rem;
  font-style: italic;
}

.playlistWrapper {
  width: 100%;
  height: 35rem;
  border-radius: 12px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.playlistEmbed {
  width: 33%;
  min-width: 100px;
  height: 33rem;
  margin: 1rem;
}

.galleryWrapper {
  width: 100vw;
  height: auto;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
  display: flex;
}

section.galleryWrapper > * {
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
}

section.galleryWrapper > * > * {
  margin: 2px;
  padding: 0;
  overflow: hidden;
}

section.galleryWrapper > * > * > * {
  width: 25vw;
  aspect-ratio: 1 / 1;
  height: auto;
  -o-transition: all .5s ease-in-out;
  filter: saturate(1.6);
  background-color: #a465de;
  background-position: center;
  background-size: cover;
  margin: 0;
  padding: 0;
  transition: all .5s ease-in-out;
  overflow: hidden;
}

section.galleryWrapper > * > * > div:hover {
  filter: saturate(1.6);
  scale: 1.1;
}

div.g1, div.g8, div.g10, div.g15 {
  aspect-ratio: 1 / 1.75;
  background-color: #883ccf;
}

div.g1 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img01_juiyab.jpg");
}

div.g2 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img02_zwkjdc.jpg");
}

div.g3 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img03_brnub7.jpg");
}

div.g4 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img04_gejt9x.jpg");
}

div.g5 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img05_ljw0mi.jpg");
}

div.g6 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img06_gipmpx.jpg");
}

div.g7 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img07_cgn4ot.jpg");
}

div.g8 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img08_rheo1a.jpg");
}

div.g9 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img09_elexgt.jpg");
}

div.g10 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img10_gwa3ph.jpg");
}

div.g11 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img11_rcyhvo.jpg");
  background-position: -1rem;
}

div.g12 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942991/Gallery%20Website/img12_tq3bri.jpg");
}

div.g13 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942991/Gallery%20Website/img13_znq1fn.jpg");
}

div.g14 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942991/Gallery%20Website/img14_ehwoyq.jpg");
}

div.g15 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img15_psahgg.jpg");
}

div.g16 {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942991/Gallery%20Website/img16_yp5nps.jpg");
}

@media (max-width: 414px) {
  .galleryWrapper {
    overflow: hidden;
  }

  section.galleryWrapper > * {
    width: 50vw;
  }

  section.galleryWrapper > * > * {
    display: flex;
  }

  section.galleryWrapper > * > * > * {
    width: 50vw;
  }
}

.selectionOuter {
  width: 100vw;
  justify-content: center;
  display: flex;
  position: absolute;
}

.selectionWrapper {
  height: 7.5vh;
  width: 100vw;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  display: flex;
  position: relative;
}

.selectionCard {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  width: 15vw;
  height: auto;
  z-index: 49;
  -o-transition: all 1.3s cubic-bezier(.075, .82, .165, 1);
  color: #faebd7;
  background-color: #000000a9;
  border-top-left-radius: 1.3rem;
  border-top-right-radius: 1.3rem;
  flex-direction: column;
  align-items: center;
  font-family: Alexandria, sans-serif;
  transition: all 1.3s cubic-bezier(.075, .82, .165, 1);
  display: flex;
}

.selectionCard > div {
  height: 4vh;
  width: 15vw;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.selectionCard > div > p {
  margin: 0;
  padding: 0;
  font-size: .9vw;
}

@media (max-width: 1920px) {
  .selectionCard > div > p {
    font-size: 1.1vw;
  }
}

@media (max-width: 1000px) {
  .selectionCard > div > p {
    font-size: 1.9vw;
  }
}

.selectionCard > button {
  height: 7.5vh;
  width: 15vw;
  background-color: #f000;
  border: none;
  margin: 0;
  padding: .5vh 0 0;
}

.cardIcon {
  color: #faebd7;
  font-size: 4.5rem;
  transition: all .3s ease-in-out;
}

.cardIcon:hover {
  color: #4e4a4a;
}

.selectionCard:hover {
  transform: translateY(-7.5vh);
}

@media (max-width: 414px), (max-width: 900px) {
  .selectionOuter {
    height: auto;
    z-index: 98;
    background-color: #11dbdb00;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .selectionWrapper {
    height: auto;
    border-top: 10px solid #ffffff28;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2vh;
    display: flex;
  }

  .selectionCard {
    width: 95vw;
    height: 6vh;
    min-height: 3rem;
    z-index: 99;
    border-radius: 1.3rem;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    margin: .3rem;
    display: flex;
  }

  .selectionCard:hover {
    transform: translateY(0);
  }

  .selectionCard > div {
    width: 30vw;
    height: auto;
    z-index: 999;
  }

  .selectionCard > div > p {
    text-align: left;
    width: 30vw;
    height: auto;
    z-index: 999;
    margin-left: 2vw;
    font-size: 1rem;
  }

  .selectionCard > button {
    height: 6vh;
    min-height: 3rem;
    color: #faebd700;
    z-index: 999;
    background-color: #575658;
    border-top-left-radius: 1.3rem;
    border-bottom-left-radius: 1.3rem;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    transition: all .3s ease-in-out;
    display: flex;
  }

  .selectionCard > button > i {
    color: #252424;
    margin: 0;
    padding: 0;
    font-size: 2rem;
  }
}

@media (max-width: 900px) {
  .selectionCard, .selectionCard > button {
    height: 8vh;
  }
}

body {
  width: 100vw;
  scroll-behavior: smooth;
  height: 100vh;
  background-color: #1b1b1b;
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  overflow-x: hidden;
}

.html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.off {
  display: none;
}

.on {
  display: flex;
}

.highlight {
  background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1678777682/Portfolio%20Website%20%28Master%29/screenshot.2253_gr2qbp.png");
}

body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
  transition-duration: 50ms;
}

body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
  transition-delay: 0;
}

body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
  transition-delay: 50ms;
}

body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
  transition-duration: .1s;
}

body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
  transition-delay: 0;
}

body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
  transition-delay: .1s;
}

body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
  transition-duration: .15s;
}

body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
  transition-delay: 0;
}

body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
  transition-delay: .15s;
}

body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
  transition-duration: .2s;
}

body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
  transition-delay: 0;
}

body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
  transition-delay: .2s;
}

body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
  transition-duration: .25s;
}

body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
  transition-delay: 0;
}

body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
  transition-delay: .25s;
}

body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
  transition-duration: .3s;
}

body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
  transition-delay: 0;
}

body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
  transition-delay: .3s;
}

body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
  transition-duration: .35s;
}

body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
  transition-delay: 0;
}

body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
  transition-delay: .35s;
}

body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
  transition-duration: .4s;
}

body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
  transition-delay: 0;
}

body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
  transition-delay: .4s;
}

body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
  transition-duration: .45s;
}

body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
  transition-delay: 0;
}

body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
  transition-delay: .45s;
}

body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
  transition-duration: .5s;
}

body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
  transition-delay: 0;
}

body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: .5s;
}

body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
  transition-duration: .55s;
}

body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
  transition-delay: 0;
}

body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
  transition-delay: .55s;
}

body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
  transition-duration: .6s;
}

body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
  transition-delay: 0;
}

body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
  transition-delay: .6s;
}

body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
  transition-duration: .65s;
}

body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
  transition-delay: 0;
}

body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
  transition-delay: .65s;
}

body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
  transition-duration: .7s;
}

body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
  transition-delay: 0;
}

body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
  transition-delay: .7s;
}

body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
  transition-duration: .75s;
}

body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
  transition-delay: 0;
}

body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
  transition-delay: .75s;
}

body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
  transition-duration: .8s;
}

body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
  transition-delay: 0;
}

body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
  transition-delay: .8s;
}

body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
  transition-duration: .85s;
}

body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
  transition-delay: 0;
}

body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
  transition-delay: .85s;
}

body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
  transition-duration: .9s;
}

body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
  transition-delay: 0;
}

body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
  transition-delay: .9s;
}

body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
  transition-duration: .95s;
}

body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
  transition-delay: 0;
}

body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
  transition-delay: .95s;
}

body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
  transition-duration: 1s;
}

body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
  transition-delay: 0;
}

body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
  transition-delay: 1s;
}

body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
  transition-duration: 1.05s;
}

body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
  transition-delay: 0;
}

body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
  transition-delay: 1.05s;
}

body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
  transition-duration: 1.1s;
}

body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
  transition-delay: 0;
}

body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
  transition-delay: 1.1s;
}

body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
  transition-duration: 1.15s;
}

body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
  transition-delay: 0;
}

body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
  transition-delay: 1.15s;
}

body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
  transition-duration: 1.2s;
}

body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
  transition-delay: 0;
}

body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
  transition-delay: 1.2s;
}

body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
  transition-duration: 1.25s;
}

body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
  transition-delay: 0;
}

body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
  transition-delay: 1.25s;
}

body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
  transition-duration: 1.3s;
}

body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
  transition-delay: 0;
}

body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
  transition-delay: 1.3s;
}

body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
  transition-duration: 1.35s;
}

body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
  transition-delay: 0;
}

body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
  transition-delay: 1.35s;
}

body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
  transition-duration: 1.4s;
}

body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
  transition-delay: 0;
}

body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
  transition-delay: 1.4s;
}

body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
  transition-duration: 1.45s;
}

body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
  transition-delay: 0;
}

body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
  transition-delay: 1.45s;
}

body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
  transition-duration: 1.5s;
}

body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
  transition-delay: 0;
}

body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
  transition-delay: 1.5s;
}

body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
  transition-duration: 1.55s;
}

body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
  transition-delay: 0;
}

body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
  transition-delay: 1.55s;
}

body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
  transition-duration: 1.6s;
}

body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
  transition-delay: 0;
}

body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
  transition-delay: 1.6s;
}

body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
  transition-duration: 1.65s;
}

body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
  transition-delay: 0;
}

body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
  transition-delay: 1.65s;
}

body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
  transition-duration: 1.7s;
}

body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
  transition-delay: 0;
}

body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
  transition-delay: 1.7s;
}

body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
  transition-duration: 1.75s;
}

body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
  transition-delay: 0;
}

body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
  transition-delay: 1.75s;
}

body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
  transition-duration: 1.8s;
}

body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
  transition-delay: 0;
}

body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
  transition-delay: 1.8s;
}

body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
  transition-duration: 1.85s;
}

body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
  transition-delay: 0;
}

body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
  transition-delay: 1.85s;
}

body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
  transition-duration: 1.9s;
}

body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
  transition-delay: 0;
}

body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
  transition-delay: 1.9s;
}

body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
  transition-duration: 1.95s;
}

body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
  transition-delay: 0;
}

body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
  transition-delay: 1.95s;
}

body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
  transition-duration: 2s;
}

body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
  transition-delay: 0;
}

body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
  transition-delay: 2s;
}

body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
  transition-duration: 2.05s;
}

body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
  transition-delay: 0;
}

body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
  transition-delay: 2.05s;
}

body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
  transition-duration: 2.1s;
}

body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
  transition-delay: 0;
}

body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
  transition-delay: 2.1s;
}

body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
  transition-duration: 2.15s;
}

body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
  transition-delay: 0;
}

body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
  transition-delay: 2.15s;
}

body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
  transition-duration: 2.2s;
}

body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
  transition-delay: 0;
}

body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
  transition-delay: 2.2s;
}

body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
  transition-duration: 2.25s;
}

body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
  transition-delay: 0;
}

body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
  transition-delay: 2.25s;
}

body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
  transition-duration: 2.3s;
}

body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
  transition-delay: 0;
}

body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
  transition-delay: 2.3s;
}

body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
  transition-duration: 2.35s;
}

body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
  transition-delay: 0;
}

body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
  transition-delay: 2.35s;
}

body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
  transition-duration: 2.4s;
}

body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
  transition-delay: 0;
}

body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
  transition-delay: 2.4s;
}

body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
  transition-duration: 2.45s;
}

body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
  transition-delay: 0;
}

body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
  transition-delay: 2.45s;
}

body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
  transition-duration: 2.5s;
}

body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
  transition-delay: 0;
}

body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
  transition-delay: 2.5s;
}

body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
  transition-duration: 2.55s;
}

body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
  transition-delay: 0;
}

body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
  transition-delay: 2.55s;
}

body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
  transition-duration: 2.6s;
}

body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
  transition-delay: 0;
}

body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
  transition-delay: 2.6s;
}

body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
  transition-duration: 2.65s;
}

body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
  transition-delay: 0;
}

body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
  transition-delay: 2.65s;
}

body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
  transition-duration: 2.7s;
}

body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
  transition-delay: 0;
}

body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
  transition-delay: 2.7s;
}

body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
  transition-duration: 2.75s;
}

body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
  transition-delay: 0;
}

body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
  transition-delay: 2.75s;
}

body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
  transition-duration: 2.8s;
}

body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
  transition-delay: 0;
}

body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
  transition-delay: 2.8s;
}

body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
  transition-duration: 2.85s;
}

body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
  transition-delay: 0;
}

body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
  transition-delay: 2.85s;
}

body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
  transition-duration: 2.9s;
}

body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
  transition-delay: 0;
}

body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
  transition-delay: 2.9s;
}

body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
  transition-duration: 2.95s;
}

body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
  transition-delay: 0;
}

body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
  transition-delay: 2.95s;
}

body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
  transition-duration: 3s;
}

body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
  transition-delay: 0;
}

body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
  transition-delay: 3s;
}

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: ease;
}

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: ease-in;
}

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: ease-out;
}

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: ease-in-out;
}

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0)scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0)scale(.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0)scale(.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0)scale(.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0)scale(.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0)scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0)scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0)scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0)scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translate3d(0, 0, 0);
}

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos="flip-left"] {
  transform: perspective(2500px)rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-right"] {
  transform: perspective(2500px)rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-up"] {
  transform: perspective(2500px)rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

[data-aos="flip-down"] {
  transform: perspective(2500px)rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

/*# sourceMappingURL=index.1b61d6d2.css.map */
