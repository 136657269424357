.bodySectionWrapper{
    position: relative;
    min-height: 25vh;
    height: auto;
    width: 100vw;
    background-color:   rgb(27, 27, 27);
    margin: 0;
    padding: 0;
    z-index: 515;
    border-bottom: none;
}

.bodySection{
    display: flex;
    flex-direction: column;
    width: 100vw;;
    position: relative;
    z-index: 50;
    padding: 0;
    padding-top: 2rem;
    margin: 0;
    align-items: center;
    height: auto;
    transition: 0.75s all ease-in-out;
    border-bottom: 1px solid rgba(255, 0, 0, 0);
}

.aboutMe, .fullaboutMe{
    display: flex;
    flex-direction: row;
    background-color: rgb(48, 45, 45);
    width: 85vw;
    height: auto;
    margin-bottom: 3vh;
    padding: 1rem;
    border-top: 4px solid;
    border-image: linear-gradient(90deg, rgba(59,0,255,1) 0%, rgba(220,27,228,1) 52%, rgba(230,189,244,1)) 1 0 0 0;
    text-overflow: ellipsis;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    box-shadow: rgba(95, 86, 114, 0.336) 0px 5px 15px;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 0.7rem;
}

.aboutMe::-webkit-scrollbar,.fullaboutMe::-webkit-scrollbar {
  display: none;
}

.fullaboutMe > div > img{
    display: none;
}

.fullaboutMe > div > div > p{
}

.aboutTitleText, .aboutTitleText:visited{
    color:white;
    font-family: 'Alexandria', sans-serif;
    font-style: bold;
    font-size: 2rem;
    margin-bottom: -0.7rem;
    padding-top: 1rem;
    text-decoration: none;
    align-items: center;
    width: auto;
    max-width: 85vw;
    height: auto;
    margin-top: -1rem;
    z-index: 55;
    transition: 0.3s all ease-in-out;
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
}

.aboutTitleText:hover, .aboutTitleText:visited:hover{
    color: rgb(98, 98, 98);
}

// .noLink{
//     pointer-events: none;
//     color: aquamarine;
// }

// .noLink:hover{
//     color:white;
// }

.aboutText{
    color: white;
    font-size: 2vh;
    transition: 0.6s ease-in-out;
    display: none;
    padding-top: 1vh;
    border-top: 1px solid rgb(240, 236, 236);
}

.aboutImg{
    width: 15vw;
    height: auto;
    aspect-ratio: auto;
    margin-top: 2vh;
    border: 2px solid rgb(255, 255, 255);
}
.aboutLeftCol{
    display: flex;
    flex-direction: column;
}

.bodyVideoWrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgb(240, 236, 236);
    margin-top: 1rem;
}

.bodyVideo,.embedSlides{
    width: 85vw;
    height: auto;
    aspect-ratio: 16/9;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    border: none;
}

.embedSlides{
    aspect-ratio: 16/10;
}

@media (max-width:414px),(max-width:900px){
.bodySection{
    padding-top: 1rem;
    margin-top: 1rem;
    height: auto;
}

div.bodySection:before{
    content: "";
    position: absolute;
    top: 0;
    width: 70vw;
    border-top: 1px solid rgb(255, 255, 255);
}

.aboutMe{
    margin: 0;
    max-height: 30vh;
}

.aboutTitleText,.aboutTitleText:visited{
    font-size: 2vh;
}

.aboutText{
    font-size: 1rem;
}

.aboutImg{
    width: 30vw;

}

.fa-link{
    font-size: 1.5rem;
    transform: scale(0.5);
}
}