.headerBar{
   display: flex;
   padding: 0rem;
   margin: 0rem;
   position: fixed;
   background-color: rgba(0, 0, 0, 0.8);
   backdrop-filter: saturate(180%);
   backdrop-filter: blur(20px);
   width: 100vw;
   z-index: 9999;
   height: 4rem;
   align-items: center;
   justify-content: flex-start;
   list-style-type: none;
   overflow: hidden;
}

.navIcon{
   color: rgb(255, 255, 255);
   font-size: 2.9vw;
   padding-left: 1rem;
}
.headerLogo{
   width: 80px;
   height: 80px;
   padding-left: 0.75rem;
   color: rgb(233, 225, 225);
}

.headerTitle{
   color: rgb(255, 255, 255);
   font-size: 2rem;
   font-family: CAH;
   padding-left: 0.75rem;
   font-family: 'Alexandria', sans-serif;
}

.aboutControls{
   width: 85vw;
   display: flex;
   position: absolute;
   justify-content: flex-end;
   padding: auto;
   transition: 1s all ease-in-out;
}

.resizeButton{
   color: white;
   transition: 0.15s ease-in-out;
   background-color: rgba(39, 37, 37, 0.685);
   padding: 0.7rem;
   font-size: 0.7rem;
   z-index: 99;
}

.resizeButton:hover{
   color: rgb(98, 98, 98);
}

@media (max-width:414px),(max-width:900px){
.headerTitle{
font-size: 2vh;
}

.headerBar{
   height: 4vh;
}
}

