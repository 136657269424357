.galleryTitle{
    width: 100vw;  
    border-top: 1px solid rgb(255, 255, 255);
}

div.galleryTitle:before{
    content: "";
    position: absolute;
    top: 0;
    width: 70vw;
    border-top: 1px solid rgb(255, 255, 255);
}

.galleryTitle> p:nth-child(1){
    color: white;
    padding-left: 5vh;
    font-size: 2rem;
}

.galleryTitle> p:nth-child(2){
    color: rgb(87, 80, 80);
    margin-top: -2rem;
    padding-left: 5vh;
    font-size: 1.2rem;
    font-style: italic;
}

.playlistWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 35rem;
    border-radius: 12px;
    overflow: hidden;
}

.playlistEmbed {
    margin: 1rem;
    width: 33%;
    min-width: 100px;
    height: 33rem;
}

.galleryWrapper{
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: auto;
    justify-content: center;
    margin: 0;
    padding: 0;
}

section.galleryWrapper > *{ //Image Colunm
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

section.galleryWrapper > * > *{ //Image Wrapper
    overflow: hidden;
    margin: 2px;
    padding: 0;
}

section.galleryWrapper > * > * > *{ //Image
    width: 25vw;
    aspect-ratio: 1 / 1;
    height: auto;
    background-color: rgb(164, 101, 222);
    transition: 0.3s;
    background-position: center;
    background-size: cover;
    transition:all 0.5s ease-in-out;
    -webkit-transition:all 0.5s ease-in-out;
    -o-transition:all 0.5s ease-in-out;
    -moz-transition:all 0.5s ease-in-out;
    overflow: hidden;
    margin: 0;
    padding: 0;
    filter: saturate(1.6);
}

section.galleryWrapper > * > * >div:hover{
    scale: (1.1);
    filter: saturate(1.6);
}

div.g1, div.g8, div.g10, div.g15{
    aspect-ratio: 1 / 1.75;
    background-color: rgb(136, 60, 207);
}

div.g1 {
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img01_juiyab.jpg");
}
div.g2{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img02_zwkjdc.jpg");
}
div.g3{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img03_brnub7.jpg")
}
div.g4{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img04_gejt9x.jpg");
}
div.g5{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img05_ljw0mi.jpg")
}
div.g6{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img06_gipmpx.jpg")
}
div.g7{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img07_cgn4ot.jpg");
}
div.g8{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img08_rheo1a.jpg");
}
div.g9{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img09_elexgt.jpg");
}
div.g10{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img10_gwa3ph.jpg");
}
div.g11{
    background-position: -1rem;
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img11_rcyhvo.jpg");
}
div.g12{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942991/Gallery%20Website/img12_tq3bri.jpg");
}
div.g13{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942991/Gallery%20Website/img13_znq1fn.jpg");
}
div.g14{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942991/Gallery%20Website/img14_ehwoyq.jpg");
}
div.g15{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942990/Gallery%20Website/img15_psahgg.jpg");
}
div.g16{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1670942991/Gallery%20Website/img16_yp5nps.jpg");
}

@media (max-width:414px){
.galleryWrapper{
    overflow: hidden;
}
section.galleryWrapper > *{ //Image Colunm
    width: 50vw;
}
section.galleryWrapper > * > *{ //Image Wrapper
    display: flex;
}

section.galleryWrapper > * > * > *{ //Image
    width: 50vw;
}
}