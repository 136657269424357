@import "navbar.scss";
@import "homepage.scss";
@import "footer.scss";
@import "body.scss";
@import "gallery.scss";
@import "selectors.scss";
@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@200;300;400&family=Major+Mono+Display&family=Montserrat:wght@400;500&display=swap');

body {
    background-color:   rgb(27, 27, 27);
    margin: 0px;
    width: 100vw;
    font-size: 1rem;
    scroll-behavior: smooth;
    font-family: 'Alexandria', sans-serif;
    font-family: 'Major Mono Display', monospace;
    font-family: 'Montserrat', sans-serif;
    height: 100vh;
    overflow-x: hidden; 
}

.html {
    margin: 0px;
    padding: 0rem;
    overflow-x: hidden;
}

.off{
    display: none;
}

.on{
    display: flex;
}

.highlight{
    background-image: url("https://res.cloudinary.com/dtjiibzcn/image/upload/v1678777682/Portfolio%20Website%20%28Master%29/screenshot.2253_gr2qbp.png");
}